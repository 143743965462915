import React from "react"

export const TitleUnderlineNavbarVerticalOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="236" height="11" viewBox="0 0 236 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M43.8761 11C38.9969 9.97886 3.27025 11.1189 0.673428 10.8111C-1.44664 10.3985 1.94141 3.13158 3.90932 2.06147C4.5991 1.6768 5.40046 1.62086 7.2365 1.79572C10.1478 2.12444 47.7916 1.47397 47.7916 1.47397C48.2887 1.69778 49.7798 0.942419 50.0537 1.21519C50.9159 1.85166 111.931 0.98438 117.135 0.949409C120.29 0.928427 117.794 1.36206 121.507 1.18721C129.419 0.837501 146.664 1.17322 152.659 0.774556C157.457 0.445832 158.491 0.431854 163.097 0.697631C167.803 0.935431 192.828 0.592715 195.689 0.0821436C196.389 -0.0577392 196.926 -0.00877595 196.926 0.152089C196.926 0.18706 214.567 0.305956 214.648 0.43185C214.78 0.592715 215.317 0.669646 215.814 0.55774C216.737 0.38988 235.514 -0.218611 235.808 0.249996C236.731 1.59287 234.144 7.31407 231.963 8.7059C229.732 10.1957 217.478 8.52407 207.628 9.41932L161.768 9.51025L151.939 10.0698C150.813 9.58717 146.481 10.8042 143.864 9.93688C142.758 9.59416 134.948 9.5452 133.223 9.90889C131.722 10.1887 91.7652 9.48225 87.6671 10.4544C80.3635 9.64312 50.7435 10.8042 43.8761 11Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineNavbarVerticalTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="216" height="11" viewBox="0 0 216 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.1578 11C35.6921 9.97886 2.99311 11.1189 0.616358 10.8111C-1.32404 10.3985 1.77688 3.13158 3.57802 2.06147C4.20934 1.6768 4.94279 1.62086 6.62324 1.79572C9.2878 2.12444 43.7415 1.47397 43.7415 1.47397C44.1964 1.69778 45.5612 0.942419 45.8119 1.21519C46.601 1.85166 102.445 0.98438 107.208 0.949409C110.096 0.928427 107.812 1.36206 111.21 1.18721C118.451 0.837501 134.235 1.17322 139.722 0.774556C144.113 0.445832 145.06 0.431854 149.275 0.697631C153.583 0.935431 176.487 0.592715 179.105 0.0821436C179.746 -0.0577392 180.238 -0.00877595 180.238 0.152089C180.238 0.18706 196.383 0.305956 196.457 0.43185C196.578 0.592715 197.07 0.669646 197.525 0.55774C198.37 0.38988 215.555 -0.218611 215.824 0.249996C216.669 1.59287 214.302 7.31407 212.305 8.7059C210.263 10.1957 199.048 8.52407 190.033 9.41932L148.059 9.51025L139.062 10.0698C138.032 9.58717 134.067 10.8042 131.672 9.93688C130.66 9.59416 123.511 9.5452 121.933 9.90889C120.559 10.1887 83.9885 9.48225 80.2377 10.4544C73.553 9.64312 46.4432 10.8042 40.1578 11Z" fill="#FFBA00" />
    </svg>
  </span>
)
