import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="310" height="10" viewBox="0 0 310 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M57.4952 8.72465C50.3282 7.97829 3.73579 10.1013 1.06623 9.99622C-1.76114 9.75296 1.658 3.89821 4.104 2.96941C5.60317 2.4055 5.49796 2.81461 18.4249 2.4055C18.4249 2.4055 37.914 1.80842 61.8743 1.11182C61.8743 1.11182 100.366 0.210661 153.363 0.000575625C157.531 -0.0160101 154.231 0.332289 159.136 0.210661C169.59 -0.0381243 192.341 0.431804 200.258 0.232775C206.609 0.072447 207.977 0.0613902 214.039 0.365461C218.208 0.575547 253.188 1.09523 257.068 0.780103C258.015 0.702703 258.699 0.75246 258.685 0.879617C258.962 0.890674 281.883 1.64809 281.988 1.76419C282.146 1.89687 282.843 1.9798 283.514 1.91346C284.75 1.7863 309.539 2.17883 309.867 2.56583C310.814 3.67154 306.488 8.03911 303.45 9.05083C300.307 10.0957 285.065 8.26025 271.599 8.57538C208.792 6.94445 209.279 7.35909 198.785 7.57471C197.338 7.17112 191.631 8.03911 188.238 7.34804C186.818 7.05502 176.613 6.94445 174.325 7.19324C173.838 7.24852 170.195 7.23746 164.935 7.19876C162.305 7.18218 159.267 7.15453 156.019 7.13242C151.679 7.09925 118.842 6.94998 114.726 7.59129C103.654 6.94998 68.0288 8.33765 57.4952 8.72465Z" fill="#FFBA00"/>
      </svg>
  </span>
)

export default titleUnderline
