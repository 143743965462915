/* eslint-disable no-undef */
import React from "react"
import TemplatePageEcommerce from "@components/pageIndustrias/pageEcommerce/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div>
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Software de automatización de ecommerce con IA en el 2024 [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/industria/solucion-omnicanal-ecommerce/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Maximiza tus ventas y conecta mejor con los clientes de tu e-commerce utilizando nuestra plataforma de automatización con IA."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Software de automatización de ecommerce con IA en el 2024"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/industria/solucion-omnicanal-ecommerce/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Maximiza tus ventas y conecta mejor con los clientes de tu e-commerce utilizando nuestra plataforma de automatización con IA."
      />
    </Helmet>
    <TemplatePageEcommerce location={location} />
  </div>
)

export default IndexPage
